import React from 'react';
import { IoCloseSharp } from 'react-icons/io5';

const ModalBucha = ({ isOpen, onClose }) => {
  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="closeBtn" onClick={onClose}>
            <IoCloseSharp />
          </button>
          <h2 className="modal-title">Увага!!!</h2>
          <p className="modal-message">
            Ресторан в Бучі поки закрито, але доставку по Бучі робимо.
            <br /> Вибачте за незручності.
          </p>
        </div>
      </div>
    )
  );
};

export default ModalBucha;

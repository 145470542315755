import React, { useState } from 'react';
import ModalDelivery from './modalDelivery';

const Window = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="window-all">
      <div>
        <button className="window-btn" onClick={openModal}>
          Умови доставки
        </button>
        {isModalOpen && (
          <ModalDelivery isOpen={isModalOpen} onClose={closeModal} />
        )}
      </div>
    </div>
  );
};

export default Window;

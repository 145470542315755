import React, { useState, useEffect } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { format } from 'date-fns';

export const generateDateOptions = () => {
  const options = [];
  const today = new Date();
  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(date.getDate() + i);
    options.push(date);
  }
  return options;
};

const MyTimePicker = ({
  value,
  onChange,
  valueDate,
  onDateChange,
  disabled,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hourOptions, setHourOptions] = useState([]);

  const handleDateChange = e => {
    const selectedDate = new Date(e.target.value);
    setSelectedDate(selectedDate);
    onChange('');
    onDateChange(selectedDate);
  };

  const handleHourChange = e => {
    onChange(e.target.value);
  };

  useEffect(() => {
    // const generateTimeOptions = () => {
    //   const options = [];
    //   const currentDate = new Date();
    //   const currentHour = currentDate.getHours();
    //   const currentMinute = currentDate.getMinutes();

    //   const openingHour = 11;
    //   const openingMinute = 30;
    //   const closingHour = 20;
    //   const maxHours = closingHour;
    //   const maxMinutes = 59;

    //   let startHour = currentHour;
    //   let startMinute = currentMinute + 30;
    //   if (startMinute >= 60) {
    //     startHour += 1;
    //     startMinute -= 60;
    //   }

    //   if (startMinute % 10 !== 0) {
    //     startMinute = Math.ceil(startMinute / 10) * 10;
    //   }

    //   for (let hours = startHour; hours <= maxHours; hours++) {
    //     for (
    //       let minutes = hours === startHour ? startMinute : 0;
    //       minutes <= (hours === maxHours ? maxMinutes : 59);
    //       minutes += 10
    //     ) {
    //       const formattedHours = String(hours).padStart(2, '0');
    //       const formattedMinutes = String(minutes).padStart(2, '0');
    //       options.push(`${formattedHours}:${formattedMinutes}`);
    //     }
    //   }
    //   return options;
    // };
    const generateTimeOptions = () => {
      const options = [];
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Перевірка, чи зараз ресторан працює
      const isRestaurantOpen = currentHour >= 11 && currentHour < 20;

      // Якщо ресторан вже працює, час генерується з поточного часу + 30 хвилин
      let startHour = isRestaurantOpen ? currentHour : 11;
      let startMinute = isRestaurantOpen ? currentMinute + 30 : 0;

      // Якщо поточний час перевищує 20:00, початковий час роботи буде 11:30 наступного дня
      if (currentHour >= 20) {
        startHour = 11;
        startMinute = 30;
      }

      // Генерація часових опцій
      for (let hours = startHour; hours <= 20; hours++) {
        for (
          let minutes = hours === startHour ? startMinute : 0;
          minutes < 60;
          minutes += 10
        ) {
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          options.push(`${formattedHours}:${formattedMinutes}`);
        }
      }

      return options;
    };

    if (valueDate && valueDate.toDateString() === new Date().toDateString()) {
      setHourOptions(generateTimeOptions());
    } else {
      const allDayOptions = [];
      const openingHour = 11;
      const closingHour = 20;
      for (let hours = openingHour; hours <= closingHour; hours++) {
        for (let minutes = 0; minutes < 60; minutes += 10) {
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          allDayOptions.push(`${formattedHours}:${formattedMinutes}`);
        }
      }
      setHourOptions(allDayOptions);
    }
  }, [selectedDate, valueDate]);

  return (
    <div className="date-and-time">
      <select
        className="date-picker"
        value={valueDate ? format(valueDate, 'yyyy-MM-dd') : ''}
        onChange={handleDateChange}
        disabled={disabled}
      >
        <option value="" disabled>
          Обрати Дату
        </option>
        {generateDateOptions().map(date => (
          <option key={date} value={format(date, 'yyyy-MM-dd')}>
            {format(date, 'dd.MM.yyyy')}
          </option>
        ))}
      </select>
      <select
        className="time-picker"
        value={value || ''}
        onChange={handleHourChange}
        disabled={!valueDate}
      >
        <option value="" disabled>
          {valueDate ? 'Обрати годину' : 'Обрати спочатку дату'}
        </option>
        {hourOptions.map(time => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

const Modal = props => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    phoneNumberPlaceholder: "Заповніть поле Ім'я",
    city: '',
    deliveryAddress: '',
    pickupTime: '',
    selectedDate: '',
  });

  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [deliveryType, setDeliveryType] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleDateChange = selectedDate => {
    setFormData(prevData => ({ ...prevData, selectedDate: selectedDate }));
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'name') {
      const phoneNumberPlaceholder = value ? '+380' : "Заповніть поле Ім'я";
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        phoneNumberPlaceholder,
      }));
    } else if (name === 'phoneNumber') {
      const phoneNumberValue = value.startsWith('+380')
        ? value
        : '+380' + value;
      setFormData(prevData => ({
        ...prevData,
        [name]: phoneNumberValue,
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCityChange = e => {
    setSelectedRestaurant(e.target.value);
    setFormData(prevData => ({
      ...prevData,
      selectedRestaurant: e.target.value,
      city: '',
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    setFormSubmitted(true);
    props.onSubmit(formData, props.utensilsQuantity);
  };

  const sendMessage = (tg, selectedDate, selectedTime) => {
    const currentTime =
      formData.pickupTime ||
      new Date().toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

    const formattedDate = format(selectedDate, 'dd.MM.yyyy');

    const orderText = `------------------------------
    <strong>🔴Ресторан: ${formData.selectedRestaurant}🔴</strong>
    <strong>Бажаний час: ${currentTime}</strong>
    <strong>Обрана дата: ${formattedDate}</strong>
    <strong>Нове замовлення:</strong>\nІмʼя: ${formData.name}\nНомер: ${
      formData.phoneNumber
    }\nМісто: ${formData.city}\nАдреса доставки: ${
      formData.deliveryAddress
    }\nНомер будинку: ${formData.building}\nЗамовлення: ${formData.orders.map(
      item => item.name + ',Кількість: ' + item.quantity
    )}\nКількість паличок: ${props.utensilsQuantity} шт.\nСумма: ${
      formData.summa
    } грн
    ------------------------------`;

    const url = `https://api.telegram.org/bot${tg.token}/sendMessage?chat_id=${
      tg.chat_id
    }&text=${encodeURIComponent(orderText)}&parse_mode=HTML`;
    const xht = new XMLHttpRequest();
    xht.open('GET', url);
    xht.send();
  };

  const sendOrder = () => {
    let tg = {
      token: '6329400225:AAGlrexZOmzXW15r6q3uKlnNVx2VmUKrvvs',
      chat_id: '6441852025',
      // chat_id: '369593494',
    };
    setFormData(prevData => ({
      ...prevData,
      utensilsQuantity: props.utensilsQuantity,
    }));
    sendMessage(tg, formData.selectedDate, formData.pickupTime);
  };

  useEffect(() => {}, [props.check]);

  const handleDeliveryType = type => {
    setDeliveryType(type);

    if (type === 'pickup') {
      setFormData(prevData => ({ ...prevData, deliveryAddress: '' }));
    }
  };

  const handleKeyDown = e => {
    const backspaceKeyCode = 8;

    if (e.keyCode === backspaceKeyCode && formData.phoneNumber === '+380') {
      e.preventDefault();
    }
  };

  const selectedRestaurantCities = {
    Немішаєво: [
      'Немішаєве',
      'Буча',
      'Мироцьке',
      'Микуличі',
      'Бородянка',
      'Клавдієве',
      'Діброва',
      'Ворзель',
      'Бабинці',
      'Пилиповичі',
      'Козинці',
      'Ясна поляна',
    ],
    Буча: [
      'Буча',
      'Ірпінь',
      'Гостомель',
      'Дмитрівка',
      'Забуччя',
      'Михайлівка-Рубежівка',
      'Горенка',
      'Пуща Водиця',
    ],
  };

  return (
    <div className="modal-overlay" onClick={props.onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="closeBtn" onClick={props.onClose}>
          <IoCloseSharp />
        </button>
        {formSubmitted ? (
          <h2 className="modal-title-thank">
            Дякуємо за замовлення! Незабаром з Вами звʼяжеться наш менеджер
          </h2>
        ) : (
          <>
            <h2 className="modal-title">Оформлення замовлення</h2>
            <form onSubmit={handleSubmit} className="modal-design">
              <div className="modal-form">
                <label>
                  Виберіть місто закладу:
                  <select
                    id="city"
                    name="city"
                    className="selected-zaklad"
                    value={selectedRestaurant}
                    onChange={handleCityChange}
                  >
                    <option value="" disabled>
                      Обрати місто закладу
                    </option>
                    <option value="Немішаєво">Немішаєве</option>
                    {/* <option value="Буча">Буча</option> */}
                  </select>
                </label>
              </div>
              <MyTimePicker
                className="time-picker"
                value={formData.pickupTime}
                onChange={time =>
                  handleChange({
                    target: { name: 'pickupTime', value: time },
                  })
                }
                valueDate={formData.selectedDate}
                onDateChange={handleDateChange}
                disabled={!selectedRestaurant}
              />
              <div className="modal-form">
                <label className="name" htmlFor="name">
                  Ім'я:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={
                    !formData.selectedDate ? 'Заповніть поля вище' : ''
                  }
                  disabled={!formData.selectedDate}
                />
              </div>
              <div className="modal-form">
                <label htmlFor="phoneNumber" className="phoneNumber">
                  Номер телефону:
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder={formData.phoneNumberPlaceholder}
                  pattern="^\+380\d{9}$"
                  disabled={!formData.name}
                />
              </div>

              {deliveryType === 'delivery' && selectedRestaurant && (
                <div className="modal-form">
                  <label htmlFor="city" className="city">
                    Місто доставки:
                  </label>
                  <select
                    className="selected-zaklad1"
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  >
                    {selectedRestaurantCities[selectedRestaurant]?.map(city => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {deliveryType === 'delivery' && (
                <div className="modal-form">
                  <label htmlFor="deliveryAddress" className="adress">
                    Вулиця:
                  </label>
                  <input
                    type="text"
                    id="deliveryAddress"
                    name="deliveryAddress"
                    value={formData.deliveryAddress}
                    onChange={handleChange}
                    disabled={!formData.name}
                  />
                </div>
              )}

              {deliveryType === 'delivery' && (
                <div className="modal-form">
                  <label htmlFor="building" className="building">
                    Будинок №:
                  </label>
                  <input
                    type="text"
                    id="building"
                    name="building"
                    value={formData.building}
                    onChange={handleChange}
                    disabled={!formData.name}
                  />
                </div>
              )}

              <div>
                <h3>Спосіб отримання замовлення:</h3>
                <div className="zakaz">
                  <button
                    className={`zakaz-btn ${
                      deliveryType === 'delivery' ? 'active' : ''
                    }`}
                    type="button"
                    onClick={() => handleDeliveryType('delivery')}
                  >
                    Доставка
                  </button>
                  <p>або</p>
                  <button
                    className={`zakaz-btn ${
                      deliveryType === 'pickup' ? 'active' : ''
                    }`}
                    type="button"
                    onClick={() => handleDeliveryType('pickup')}
                  >
                    Самовивіз
                  </button>
                </div>
              </div>

              <button
                className="modal-submit-btn"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  handleSubmit(e);
                  sendOrder();
                }}
                disabled={!formData.name || !formData.phoneNumber}
              >
                Замовити &#129303;
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;

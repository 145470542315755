import React from 'react';
import { IoCloseSharp } from 'react-icons/io5';

const ModalDelivery = ({ isOpen, onClose }) => {
  const handleModalClick = () => {
    onClose();
  };

  const handleButtonClick = e => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className={`modal-overlay2 ${isOpen ? 'open' : ''}`}
      onClick={handleModalClick}
    >
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="closeBtn" onClick={handleButtonClick}>
          <IoCloseSharp />
        </button>
        <div className="modal-del-adress">
          <p className="modal-delivery">
            📍Доставка Немішаєве, Мироцьке, Микуличі - 80 грн,при замовленні на
            суму 800 грн доставка безкоштовно.
            <br />
            📍Буча 130 грн, 📍Бородянка 180 грн, 📍Клавдієве, Діброва, Ворзель
            130 грн, 📍Бабинці 150 грн, 📍Пилиповичі, Козинці 140 грн, 📍Ясна
            поляна 100 грн. <br /> При замовленні на суму 1000 грн доставка
            безкоштовно.
          </p>
          {/* <p className="modal-delivery">
            📍Буча 80 грн 📍Ірпінь 120, 160 грн 📍Гостомель 120 грн 📍Дмитрівка
            180 грн 📍Забуччя 150 грн 📍Михайлівка-Рубежівка 160 грн 📍Горенка
            150 грн 📍Пуща Водиця 180 грн <br /> Замовлення від 1000грн -
            безкоштовно
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default ModalDelivery;

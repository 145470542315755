import React, { useState } from 'react';
import { FaTiktok } from 'react-icons/fa';
import { IoLogoInstagram } from 'react-icons/io5';
import LoginModal from './LoginModal';

export default function Footer(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <footer>
      <div className="footer">
        <div>
          <h2 className="footer-title">Наші адреси</h2>
          <div className="footer-addres">
            <a
              className="footer-addres-link"
              href="https://maps.app.goo.gl/GF5vah6Qa7hiAc7w9"
            >
              📍Немішаєве,вул.Заводська 49а <br />
              ТЦ"Фора"1 поверх
            </a>
            {/* <a
              className="footer-addres-link"
              href="https://maps.app.goo.gl/DdzHJnRRxokgczdP6"
            >
              📍Буча Вул.Героїв Майдану 13 <br />
              «Престиж центр»
            </a> */}
          </div>
        </div>
        <div>
          <h2 className="footer-title">Наші контакти</h2>
          <ul className="footer-number">
            <div className="nemishbucha">
              <div className="nemish">
                <p className="teltext">Немішаєве</p>
                <li>
                  <a className="footer-number-color" href="tel:+380730810705">
                    073-081-07-05
                  </a>
                </li>
                <li>
                  <a className="footer-number-color" href="tel:+380980810705">
                    098-081-07-05
                  </a>
                </li>
                <li>
                  <a className="footer-number-color" href="tel:+380660810705">
                    066-081-07-05
                  </a>
                </li>
              </div>
              {/* <div className="bucha">
                <p className="teltext">Буча</p>
                <li>
                  <a className="footer-number-color" href="tel:+380502239191">
                    050-223-91-91
                  </a>
                </li>
                <li>
                  <a className="footer-number-color" href="tel:+380682239191">
                    068-223-91-91
                  </a>
                </li>
                <li>
                  <a className="footer-number-color" href="tel:+380732239191">
                    073-223-91-91
                  </a>
                </li>
              </div> */}
            </div>
          </ul>
        </div>
        <div>
          <h2 className="footer-title">Ми у соціальних мережах</h2>
          <ul className="footer-soc">
            <li className="footer-soc-list">
              <a href="https://www.instagram.com/sushi_love_premium">
                <IoLogoInstagram className="insta" />
              </a>
            </li>
            <li className="footer-soc-list">
              <a href="https://www.tiktok.com/@sushi_love_premium?_t=8j1cvozg5Yr&_r=1">
                <FaTiktok className="tiktok" />
              </a>
            </li>
          </ul>
          {/* <button onClick={() => setIsModalOpen(true)}>Зайти як адмін</button> */}
          {isModalOpen && (
            <LoginModal
              setIsModalOpen={setIsModalOpen}
              setIsAdmin={props.showAdminPage}
            />
          )}
        </div>
      </div>
    </footer>
  );
}

import React, { Component } from 'react';

export class ShowFullItem extends Component {
  render() {
    const { name, price, image, gram, litr, menu } = this.props.item;
    const displayValue = litr ? `${litr}л` : `${gram}г`;
    return (
      <div className="full-item">
        <div>
          <img
            src={image}
            alt={`Зображення товару: ${name}`}
            onClick={() => this.props.onShowItem(this.props.item)}
          />
          <h2>{name}</h2>
          <b>{price}грн</b>
          <p>{displayValue}</p>
          <p>{menu}</p>
          <div
            className="add-to-cart"
            onClick={() => this.props.onAdd(this.props.item)}
          >
            Хочу
          </div>
        </div>
      </div>
    );
  }
}

export default ShowFullItem;

import React, { useState } from 'react';

const LoginModal = props => {
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (password === '12345') {
      props.setIsAdmin(true);
    } else {
      alert('Невірний пароль. Спробуйте ще раз.');
    }
    setPassword('');
    props.setIsModalOpen(false);
  };

  return (
    <div className="adminPassword">
      <h2>Введіть пароль для входу в адмін панель</h2>
      <input
        className="passwordInput"
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button className="adminBTN" onClick={handleLogin}>
        Увійти
      </button>
    </div>
  );
};

export default LoginModal;

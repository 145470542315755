import React, { Component } from 'react';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flyingToCart: false,
    };
  }

  handleAddToCart = () => {
    const { onAdd, item } = this.props;
    this.setState({ flyingToCart: true });

    setTimeout(() => {
      let clone = Object.assign({}, item);
      clone.quantity = 1;
      onAdd(clone);
      this.setState({ flyingToCart: false });
    }, 1000);
  };

  render() {
    const { name, price, image, gram, litr, valuest } = this.props.item;
    const { flyingToCart } = this.state;
    const displayValue = litr ? `${litr}л` : gram ? `${gram}г` : `${valuest}`;

    return (
      <div className={`item ${flyingToCart ? 'flying-to-cart' : ''}`}>
        <img
          className="item-img"
          src={image}
          alt={`Зображення товару: ${name}`}
          onClick={() => this.props.onShowItem(this.props.item)}
        />
        <h2>{name}</h2>
        <b>{price}грн</b>
        <p>{displayValue}</p>
        <div
          className={`add-to-cart ${flyingToCart ? 'flying' : ''}`}
          onClick={this.handleAddToCart}
        >
          Хочу
        </div>
      </div>
    );
  }
}

export default Item;

import React, { useState, useEffect } from 'react';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import Order from './order';
import logo from '../images/logo.PNG';
import Modal from './modal';

// Компонент, який відображає замовлення
const ShowOrders = ({
  orders,
  updatePrice,
  summa,
  deleteOrder,
  updatePribory,
  updateOrders = () => {},
}) => {
  // Функція для обробки видалення товару з замовлення
  const handleDelete = (id, price, quantity) => {
    updatePrice(-price * quantity);
    const updOrders = orders.filter(el => el.id !== id);
    updateOrders(updOrders);
    deleteOrder(id);
  };

  // Повертає список товарів у корзині та загальну суму
  return (
    <div>
      {orders.map((el, index) => (
        <Order
          key={el.id}
          item={el}
          onDelete={(id, price, quantity) =>
            handleDelete(id, price, quantity, updateOrders)
          }
          updatePrice={updatePrice}
          updateOrders={updateOrders}
          isLastItem={index === orders.length - 1}
          updatePribory={updatePribory}
        />
      ))}
      <p className="summa">Всього до сплати: {summa}грн</p>
    </div>
  );
};

// Компонент, який відображає повідомлення про порожню корзину
const ShowNothing = () => {
  return (
    <div className="empty">
      <h2>Ви ще нічого не вибрали &#128523;</h2>
    </div>
  );
};

// Основний компонент шапки сайту
export default function Header(props) {
  const [cartOpen, setCartOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [summa, setSumma] = useState(
    props.orders.reduce(
      (total, el) => total + Number(el.price * el.quantity),
      0
    )
  );

  // Оновлення загальної суми при зміні замовлення
  useEffect(() => {
    let totalSum = props.orders.reduce(
      (total, el) => total + Number(el.price * el.quantity),
      0
    );
    setSumma(totalSum);
  }, [props.orders]);

  // Функція для оновлення загальної суми
  const updatePrice = newPrice => {
    setSumma(summa => summa + newPrice);
  };

  // Функція для отримання загальної кількості товарів у корзині
  const getTotalQuantity = () => {
    return props.orders.reduce((total, el) => total + el.quantity, 0);
  };

  const createNewCheck = check => {
    check.orders = props.orders;
    check.summa = summa;
    check.utensilsQuantity = props.utensilsQuantity;
    props.createCheck(check);
  };

  // Функція для відкриття модального вікна
  const openModal = () => {
    setModalOpen(true);
    setCartOpen(false);
  };

  return (
    <header>
      <div className="header">
        <div className="nav">
          <h3 className="header-nav-title">Графік роботи:</h3>
          <p className="header-nav-clock">11:00-21:00</p>
        </div>
        <span className="logo">
          <img src={logo} alt="Sushi Love" />
        </span>

        {/* Кнопка корзини */}
        <HiOutlineShoppingBag
          onClick={() => setCartOpen(!cartOpen)}
          className={`shop-cart-button ${cartOpen && 'active'}`}
        />

        {/* Вивід кількості товарів у корзині */}
        {getTotalQuantity() > 0 && (
          <div className="cart-quantity">{getTotalQuantity()}</div>
        )}

        {/* Вивід вікна корзини */}
        {cartOpen && (
          <div
            className="shop-cart"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {props.orders.length > 0 ? (
              <ShowOrders
                orders={props.orders}
                updatePrice={updatePrice}
                summa={summa}
                deleteOrder={props.onDelete}
                updateOrders={props.updateOrders}
                updatePribory={props.updatePribory}
              />
            ) : (
              <ShowNothing />
            )}
            {props.orders.length > 0 && (
              <button className="shop-cart__button" onClick={openModal}>
                Оформити замовлення
              </button>
            )}
          </div>
        )}

        {/* Вивід модального вікна */}
        {modalOpen && (
          <Modal
            onClose={() => setModalOpen(false)}
            onSubmit={check => createNewCheck(check)}
            utensilsQuantity={props.utensilsQuantity}
          ></Modal>
        )}
      </div>
    </header>
  );
}

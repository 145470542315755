import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import palochkiImage from '../images/palochki.png';

const Order = ({
  item,
  onDelete,
  updatePrice,
  updateOrders,
  isLastItem,
  updatePribory,
}) => {
  const [quantity, setQuantity] = useState(item.quantity || 1);
  const [utensilsQuantity, setUtensilsQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    updatePrice(item.price);
    item.quantity = item.quantity + 1;
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      updatePrice(-item.price);
      item.quantity = item.quantity - 1;
    }
  };

  const handleDelete = () => {
    onDelete(item.id, item.price, item.quantity, updateOrders);
  };

  const handleUtensilsIncrement = () => {
    setUtensilsQuantity(prevUtensilsQuantity => {
      updatePribory(prevUtensilsQuantity + 1);
      return prevUtensilsQuantity + 1;
    });
  };

  const handleUtensilsDecrement = () => {
    if (utensilsQuantity > 1) {
      setUtensilsQuantity(prevUtensilsQuantity => {
        updatePribory(prevUtensilsQuantity - 1);
        return prevUtensilsQuantity - 1;
      });
    }
  };

  return (
    <div className="item">
      <img src={item.image} alt={`Зображення товару: ${item.name}`} />
      <h2>{item.name}</h2>
      <b>{item.price * item.quantity}грн</b>
      <p>{item.litr ? `${item.litr}л` : `${item.gram}г`}</p>
      <div className="quantity-control">
        <button className="IncDec" onClick={handleDecrement}>
          -
        </button>
        <span>{item.quantity}</span>
        <button className="IncDec" onClick={handleIncrement}>
          +
        </button>
      </div>
      <FaTrash className="delete-icon" onClick={handleDelete} />

      {isLastItem && (
        <div className="pribory-all">
          <h2 className="pribory-title">Палички</h2>
          <div className="pri-all">
            <img src={palochkiImage} alt="Палочки" width={70} />
            <div className="quantity-controll">
              <button className="IncDec" onClick={handleUtensilsDecrement}>
                -
              </button>
              <span>{utensilsQuantity}</span>
              <button className="IncDec" onClick={handleUtensilsIncrement}>
                +
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
